import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as styles from "./navigation.module.scss"

const Navigation = () => {

  const cover = 'url(/sd-logo.png) 50% calc(50% + (var(--header-height)/2) ) / 96px no-repeat #1C1C1C'

  return (

    <nav className={styles.container}>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/bns/level-2">Level 2</AniLink>
        </div>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-2">Womens</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Bra Studio</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-2">Kids</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Foot Measuring</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-2">Football</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-2">Shirt Personalisation</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
        <ul className={styles.logogrid}>
          <li>
            <svg width="82" height="23" viewBox="0 0 82 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1907 2.02338V4.77406C20.1904 5.08167 20.068 5.37657 19.8505 5.59409C19.633 5.8116 19.3381 5.93395 19.0304 5.93433C18.8524 5.9348 18.6768 5.89373 18.5175 5.81432C16.3255 4.73834 14.1207 4.17106 11.9301 4.17106C7.70051 4.17106 4.50969 7.33327 4.50969 11.5315C4.50969 15.8268 7.72624 18.8275 12.3317 18.8275C13.7606 18.8275 15.0666 18.5175 16.2055 18.1731V13.7663H12.5388C12.3863 13.7665 12.2353 13.7366 12.0944 13.6784C11.9534 13.6201 11.8254 13.5347 11.7175 13.4269C11.6096 13.3192 11.524 13.1912 11.4656 13.0503C11.4072 12.9095 11.3771 12.7585 11.3771 12.606V9.65811H19.462C19.7697 9.65811 20.0648 9.78034 20.2824 9.99794C20.5 10.2155 20.6223 10.5107 20.6223 10.8184V21.0281L20.205 21.211C17.3171 22.4827 14.9109 23 11.9001 23C5.0027 23 0 18.2159 0 11.6229C0 4.99696 5.15701 0 11.993 0C14.5879 0 16.4041 0.428697 19.7649 1.8419L20.1907 2.02338Z" fill="#F8F8F8"/>
              <path d="M29.8486 12.9955L31.982 7.13695L34.1153 12.9955H29.8486ZM33.4109 0.152345H31.2589C31.0341 0.151967 30.814 0.217916 30.6265 0.34195C30.4389 0.465984 30.2921 0.64261 30.2044 0.849679L22.321 20.3002C22.2023 20.5788 22.199 20.893 22.3118 21.1741C22.4245 21.4551 22.6442 21.6799 22.9225 21.7991L26.2463 22.8894L28.3525 17.0308H35.5442L37.6505 22.8894L40.9756 21.7991C41.2537 21.6796 41.4731 21.4547 41.5856 21.1737C41.6981 20.8927 41.6945 20.5786 41.5757 20.3002L33.4109 0.152345Z" fill="#F8F8F8"/>
              <path d="M66.0007 1.28406C66.0096 0.979805 66.1372 0.691121 66.3562 0.479659C66.5751 0.268198 66.8681 0.150737 67.1724 0.15236H80.7286V3.12167C80.7288 3.27553 80.6986 3.42789 80.6398 3.57006C80.5809 3.71222 80.4946 3.84138 80.3857 3.9501C80.2769 4.05883 80.1476 4.14499 80.0054 4.20365C79.8632 4.26232 79.7108 4.29233 79.5569 4.29195H70.459V9.34464H80.4185V12.3168C80.4182 12.6269 80.2947 12.9242 80.0753 13.1434C79.8558 13.3626 79.5584 13.4857 79.2483 13.4857H70.459V18.7227H81.0044V21.6977C81.0044 22.0078 80.8813 22.3053 80.6622 22.5247C80.443 22.7441 80.1456 22.8676 79.8355 22.868H67.1681C67.0114 22.8669 66.8565 22.8346 66.7124 22.773C66.5683 22.7114 66.4379 22.6217 66.3288 22.5092C66.2198 22.3966 66.1342 22.2635 66.0771 22.1176C66.02 21.9716 65.9926 21.8158 65.9964 21.6591L66.0007 1.28406Z" fill="#F8F8F8"/>
              <path d="M47.2272 0.152344H44.3551C44.0519 0.152344 43.7611 0.272798 43.5468 0.487178C43.3324 0.701558 43.2119 0.992304 43.2119 1.29548V20.626C43.2109 20.8512 43.2764 21.0717 43.4002 21.2598C43.524 21.4479 43.7006 21.5953 43.9078 21.6834L47.6059 22.8894V9.38464C48.3718 10.6707 51.2853 15.3861 51.9284 16.3292C52.0643 16.5307 52.2454 16.6976 52.4573 16.8167C52.6692 16.9357 52.9059 17.0037 53.1487 17.0151H54.0403C54.2842 17.0091 54.523 16.9434 54.7357 16.8238C54.9484 16.7043 55.1286 16.5344 55.2606 16.3292C55.8607 15.3504 58.8172 10.6635 59.5817 9.38464V22.8894L63.2826 21.6834C63.489 21.5946 63.6647 21.447 63.7877 21.2589C63.9107 21.0708 63.9756 20.8507 63.9742 20.626V0.152344H60.5805C60.4164 0.152736 60.2544 0.189451 60.1062 0.259862C59.9579 0.330273 59.8271 0.432648 59.7231 0.559601C59.4816 0.909688 59.4917 0.906819 53.5959 10.372L47.2272 0.152344Z" fill="#F8F8F8"/>
            </svg>
          </li>
          <li>
            <svg width="117" height="13" viewBox="0 0 117 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.3646 5.11509C7.22732 5.27902 7.05103 5.41108 6.84974 5.50087C6.64844 5.59067 6.42759 5.63577 6.20443 5.63264H2.29669V3.58763H6.20443C6.42929 3.58334 6.65221 3.62693 6.85625 3.71506C7.04672 3.79232 7.21061 3.91708 7.3298 4.07557C7.449 4.23407 7.51885 4.42014 7.53174 4.61329C7.53367 4.79491 7.47463 4.97255 7.36287 5.12139L7.3646 5.11509ZM2.29669 7.43067H6.18917C6.41341 7.42894 6.6352 7.47467 6.8377 7.5644C7.02307 7.63731 7.18258 7.75747 7.29789 7.91109C7.41321 8.0647 7.47962 8.24553 7.48952 8.43275C7.4921 8.60766 7.43275 8.7785 7.32065 8.91885C7.18839 9.0795 7.0173 9.20883 6.82129 9.29629C6.62528 9.38376 6.40984 9.4269 6.19255 9.42223H2.30007L2.29669 7.43067ZM9.37417 3.19593C8.71894 2.33231 7.50475 1.7959 6.20443 1.7959H0V11.2155H6.18917C6.82066 11.2179 7.44447 11.0861 8.01305 10.8301C9.13943 10.322 9.81153 9.42537 9.81153 8.43275C9.51154 8.19764 9.27114 7.90355 9.10767 7.57162C8.9442 7.23969 8.86171 6.87814 8.86607 6.51297C8.87043 6.1478 8.96155 5.78809 9.1329 5.45963C9.30426 5.13116 9.5516 4.84212 9.85712 4.61329C9.86088 4.10566 9.69181 3.60999 9.37417 3.19751" fill="#F8F8F8"/>
              <path d="M75.8509 1.67188C74.1504 1.67188 72.5342 2.19099 71.423 3.08765C70.8542 3.49141 70.3914 4.01039 70.0696 4.60532C69.7478 5.20026 69.5753 5.85559 69.5654 6.52172C69.5654 7.79907 70.241 9.05279 71.413 9.95889C72.6719 10.896 74.2412 11.3964 75.8526 11.3747H81.1467V5.72888H76.3119L73.9899 7.51903H78.818V9.58452H75.8441C74.8293 9.59923 73.8405 9.28441 73.0476 8.69412C72.6912 8.43683 72.4013 8.10843 72.199 7.73303C71.9968 7.35763 71.8875 6.94477 71.879 6.52483C71.8853 6.10385 71.9951 5.68981 72.2 5.31456C72.405 4.9393 72.6995 4.61287 73.0611 4.3603C73.8466 3.76241 74.8351 3.44501 75.8492 3.46518H78.8231L81.1434 1.67345L75.8509 1.67188Z" fill="#F8F8F8"/>
              <path d="M63.8338 3.58021V8.22397L55.4831 1.79004H53.7656V11.2081L56.0859 9.41952V4.7852L64.435 11.2097H66.1541V1.79004L63.8338 3.58021Z" fill="#F8F8F8"/>
              <path d="M94.4541 3.1599V2.70998C94.4541 2.47717 94.2261 2.33561 93.9289 2.33561C93.6317 2.33561 93.3953 2.47717 93.3953 2.70998V4.71882C93.3953 4.95164 93.6233 5.09324 93.9205 5.09324C94.2177 5.09324 94.4541 4.95164 94.4541 4.71882V4.20288H93.9475V3.58463H95.3255V4.69524C95.3255 5.28672 94.7496 5.70987 93.9272 5.70987C93.1048 5.70987 92.5205 5.28672 92.5205 4.69524V2.73045C92.5205 2.13897 93.0946 1.71582 93.9187 1.71582C94.7428 1.71582 95.3255 2.13897 95.3255 2.73045V3.15833L94.4541 3.1599Z" fill="#F8F8F8"/>
              <path d="M98.2367 2.66132L97.9377 4.19193H98.5271L98.2367 2.66132ZM98.669 4.81014H97.7959L97.5612 5.63601H96.7168L97.7097 1.79297H98.772L99.7751 5.63601H98.907L98.669 4.81014Z" fill="#F8F8F8"/>
              <path d="M104.917 5.63602H104.193V2.80918L103.417 5.63602H102.786L102.017 2.83121V5.63602H101.293V1.79297H102.294L103.105 4.32879L103.916 1.79297H104.917V5.63602Z" fill="#F8F8F8"/>
              <path d="M107.513 1.79297H106.64V5.63445H107.513V1.79297Z" fill="#F8F8F8"/>
              <path d="M87.2686 0H86.3955V13H87.2686V0Z" fill="#F8F8F8"/>
              <path d="M111.998 1.79297V5.63602H111.258L109.966 3.19302V5.63602H109.227V1.79297H109.966L111.258 4.25171V1.79297H111.998Z" fill="#F8F8F8"/>
              <path d="M115.589 3.1599V2.70998C115.589 2.47717 115.362 2.33561 115.063 2.33561C114.765 2.33561 114.531 2.47717 114.531 2.70998V4.71882C114.531 4.95164 114.758 5.09324 115.057 5.09324C115.356 5.09324 115.589 4.95164 115.589 4.71882V4.20288H115.082V3.58463H116.46V4.69524C116.46 5.28672 115.884 5.70987 115.06 5.70987C114.236 5.70987 113.653 5.28672 113.653 4.69524V2.73045C113.653 2.13897 114.229 1.71582 115.053 1.71582C115.877 1.71582 116.46 2.13897 116.46 2.73045V3.15833L115.589 3.1599Z" fill="#F8F8F8"/>
              <path d="M94.0433 8.23163L93.7444 9.76224H94.3338L94.0433 8.23163ZM94.4756 10.3805H93.6026L93.3678 11.2048H92.5234L93.5164 7.36328H94.5752L95.5767 11.2048H94.7104L94.4756 10.3805Z" fill="#F8F8F8"/>
              <path d="M97.9734 9.09954H98.4631C98.7536 9.09954 98.9242 8.97528 98.9242 8.75819V8.31772C98.9242 8.10063 98.7553 7.97637 98.4631 7.97637H97.9734V9.09954ZM99.0812 11.1996L98.4783 9.71933H97.9717V11.1996H97.0986V7.36914H98.4057C99.2214 7.36914 99.7972 7.77028 99.7972 8.35075V8.75347C99.7962 8.9114 99.7497 9.06618 99.6623 9.20154C99.575 9.3369 99.45 9.44782 99.3007 9.52269L99.9881 11.2106L99.0812 11.1996Z" fill="#F8F8F8"/>
              <path d="M102.459 7.9815V9.01817H103.454V9.63797H102.459V10.5881H103.753V11.2063H101.586V7.36328H103.753V7.9815H102.459Z" fill="#F8F8F8"/>
              <path d="M108.131 7.36328V11.2063H107.391L106.097 8.76333V11.2063H105.359V7.36328H106.097L107.391 9.82202V7.36328H108.131Z" fill="#F8F8F8"/>
              <path d="M111.181 8.23163L110.884 9.76224H111.473L111.181 8.23163ZM111.615 10.3805H110.742L110.507 11.2048H109.663L110.658 7.36328H111.717L112.718 11.2048H111.852L111.615 10.3805Z" fill="#F8F8F8"/>
              <path d="M114.003 10.1594V10.002H114.878V10.2459C114.878 10.5605 115.119 10.6911 115.41 10.6911C115.476 10.696 115.542 10.6879 115.604 10.6673C115.666 10.6467 115.723 10.6141 115.771 10.5717C115.819 10.5292 115.857 10.4778 115.882 10.4209C115.907 10.3639 115.919 10.3027 115.916 10.2412C115.916 9.45461 114.091 9.53012 114.091 8.35345C114.091 7.70691 114.609 7.29004 115.42 7.29004C116.231 7.29004 116.74 7.67545 116.74 8.28267V8.53276H115.866V8.22759C115.866 8.02781 115.668 7.91298 115.42 7.91298C115.361 7.90663 115.302 7.91238 115.246 7.92976C115.19 7.94714 115.139 7.97574 115.097 8.01356C115.054 8.05138 115.021 8.09751 114.999 8.14863C114.978 8.19976 114.969 8.25463 114.972 8.3094C114.972 8.98111 116.798 8.90089 116.798 10.1656C116.798 10.8484 116.251 11.2888 115.391 11.2888C114.532 11.2888 113.998 10.8484 113.998 10.1656" fill="#F8F8F8"/>
              <path d="M19.0981 3.58179L21.4184 1.79004H12.9883V3.58179H19.0981Z" fill="#F8F8F8"/>
              <path d="M15.3103 9.41777V7.43568H18.0916L20.4119 5.64551H12.9883V11.2095H19.0964L21.4184 9.41777H15.3103Z" fill="#F8F8F8"/>
              <path d="M27.4248 9.44154V1.79004H25.1045V11.2081H32.5822L34.8721 9.44154H27.4248Z" fill="#F8F8F8"/>
              <path d="M48.0397 6.50036C48.0397 8.29368 46.1483 9.7535 43.8179 9.7535C41.4874 9.7535 39.596 8.29368 39.596 6.50036C39.596 4.70704 41.4874 3.24723 43.8179 3.24723C46.1483 3.24723 48.0397 4.70704 48.0397 6.50036ZM43.8179 1.45703C40.2158 1.45703 37.2842 3.71914 37.2842 6.50036C37.2842 9.28158 40.2158 11.5437 43.8179 11.5437C47.4199 11.5437 50.3532 9.28158 50.3532 6.50036C50.3532 3.71914 47.4216 1.45703 43.8179 1.45703Z" fill="#F8F8F8"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/bns/level-1">Level 1</AniLink>
        </div>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-1">Mens</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Footwear</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-1">Running</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Gait Analysis</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-1">Womens</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-1">Accessories</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/bns/">Level 0</AniLink>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" fill="white"/>
            <path d="M17.53 22.0372C18.186 22.4669 18.8306 22.6576 19.725 22.6576C20.6792 22.6576 21.3594 22.3237 21.3594 21.7988C21.3594 21.2616 20.8941 21.0111 19.5102 20.8084C17.7684 20.558 16.7544 20.0328 16.7544 18.3508C16.7544 16.8474 18.1739 15.7139 20.095 15.7139C21.3594 15.7139 22.2545 15.9643 23.1133 16.5015L22.0873 17.8499C21.2406 17.3966 20.7034 17.3013 19.9157 17.3013C19.128 17.3013 18.4599 17.6713 18.4599 18.1967C18.4599 18.8172 18.9253 18.9958 20.4287 19.2228C22.3258 19.5088 23.2086 20.2244 23.2086 21.5495C23.2086 23.2436 21.5742 24.2575 19.6652 24.2575C18.4962 24.2575 17.3627 24.0668 16.3965 23.362L17.53 22.0372V22.0372Z" fill="#161616"/>
            <path d="M11.7442 24.2559C9.54914 24.2559 8.21289 23.0271 8.21289 20.5451V15.9525H10.0265V20.4379C10.0265 21.846 10.7424 22.5732 11.8758 22.5732C12.9971 22.5732 13.7129 21.8453 13.7129 20.4976V15.9521H15.5266V20.486C15.5259 22.9791 13.9627 24.2556 11.7441 24.2556L11.7442 24.2559Z" fill="#161616"/>
            <path d="M28.2683 24.2557C25.9181 24.2557 24.2236 22.669 24.2236 20.0684C24.2236 17.7303 26.001 15.7139 28.5305 15.7139C30.0815 15.7139 31.0479 16.2268 31.6562 16.8474L30.5825 18.1836C29.962 17.6229 29.3779 17.3959 28.5184 17.3959C27.1821 17.3959 26.1204 18.4938 26.1204 19.9488C26.1204 21.3451 26.9672 22.5736 28.5062 22.5736C29.4604 22.5736 30.0331 22.299 30.6656 21.7148L31.6917 22.8838C30.8449 23.7426 29.8666 24.2557 28.2678 24.2557H28.2683Z" fill="#161616"/>
          </svg>
        </div>
        <ul className={styles.sublist}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">Sneaker Zone</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">Menswear</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">Lounge</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">Denim</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">Womenswear</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">Kids</AniLink>
          </li>
        </ul>
        <ul className={styles.logolist}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/">
              <svg width="97" height="13" viewBox="0 0 97 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2688 0.168945H12.7708L7.44425 12.6596L7.4043 12.7528H9.65475L11.1661 9.16409H16.0332L17.5646 12.653L17.6179 12.7795H19.8617L14.2888 0.168945H14.2688ZM15.1211 7.21326H11.9851L13.4898 3.58457L15.1211 7.21326Z" fill="#F8F8F8"/>
                <path d="M26.7386 10.8687C25.5108 10.9151 24.3144 10.4748 23.4095 9.64362C22.9846 9.24065 22.6498 8.75228 22.4272 8.21062C22.2045 7.66896 22.0991 7.0863 22.1179 6.50098C22.105 5.91179 22.217 5.32654 22.4465 4.78374C22.676 4.24094 23.0178 3.75288 23.4495 3.35168C24.348 2.50594 25.5452 2.051 26.7786 2.08663C28.2262 2.11944 29.6402 2.53031 30.88 3.27844L31.1996 3.46486V1.08125L31.0664 1.02133C29.6823 0.390703 28.1858 0.0442962 26.6654 0.00263275C24.8722 -0.0369923 23.1363 0.635786 21.8382 1.87357C21.1949 2.46521 20.6843 3.18648 20.34 3.98983C19.9957 4.79318 19.8255 5.66038 19.8408 6.53427C19.8399 7.65811 20.1332 8.76261 20.6916 9.73795C21.2499 10.7133 22.0538 11.5255 23.0234 12.0938C24.1239 12.6719 25.3565 12.9519 26.5988 12.9061C27.7978 12.92 28.9892 12.7147 30.1143 12.3002C30.4645 12.1694 30.805 12.0136 31.133 11.8341L31.2462 11.7742V9.32403L30.9133 9.53709C29.6791 10.374 28.2294 10.8364 26.7386 10.8687Z" fill="#F8F8F8"/>
                <path d="M43.1184 0.163086H40.3553L35.5415 5.39638V0.163086H33.3643V12.7669H35.5415V7.0476L41.4006 12.707L41.4606 12.7669H44.4567L37.6454 6.14209L43.1184 0.163086Z" fill="#F8F8F8"/>
                <path d="M63.2727 8.17283L60.0834 0.296249L60.0302 0.163086H58.0993L54.8701 8.17283L51.7208 0.296249L51.6675 0.163086H49.457L54.4706 12.6538L54.5239 12.7869H55.1897L59.0514 3.27244L62.8266 12.6538L62.8798 12.7869H63.5523L68.5992 0.249642L68.6391 0.163086H66.4419L63.2727 8.17283Z" fill="#F8F8F8"/>
                <path d="M72.0083 0.163086H69.8311V12.8002H72.0083V0.163086Z" fill="#F8F8F8"/>
                <path d="M94.2529 5.82208L92.9213 5.03642C92.457 4.78648 92.0365 4.46266 91.6762 4.07765C91.4509 3.83713 91.3228 3.52161 91.3167 3.19211C91.3163 3.02578 91.3582 2.86208 91.4386 2.71646C91.519 2.57084 91.6351 2.4481 91.7761 2.35985C92.1525 2.10558 92.6008 1.97949 93.0545 2.00031C93.8747 2.01758 94.6652 2.31078 95.2983 2.83257L95.3649 2.88584L96.1305 0.981612L95.9841 0.895056C95.1321 0.314619 94.1253 0.00376428 93.0944 0.00286596C92.093 -0.0350717 91.1139 0.304769 90.3513 0.95498C90.0021 1.26803 89.7257 1.65382 89.5416 2.0852C89.3576 2.51657 89.2703 2.98304 89.286 3.45178C89.2734 4.10494 89.4719 4.74473 89.8519 5.27611C90.3549 5.91057 90.9797 6.43803 91.6896 6.82746L93.0212 7.63309C94.0132 8.2523 94.5193 8.9048 94.5193 9.55729C94.5194 9.74486 94.4777 9.93009 94.397 10.0994C94.3164 10.2688 94.1989 10.4179 94.0532 10.536C93.7057 10.8119 93.2716 10.9558 92.8281 10.9422C91.8399 10.9105 90.9022 10.4978 90.2115 9.79033L90.1449 9.72375L89.3259 11.6812L89.4324 11.7545C90.411 12.508 91.613 12.9134 92.8481 12.9063C93.8273 12.9337 94.78 12.586 95.5113 11.9342C95.8538 11.6084 96.1238 11.2142 96.3039 10.7771C96.484 10.3401 96.5701 9.87001 96.5567 9.3975C96.5833 7.95268 95.791 6.74756 94.2529 5.82208Z" fill="#F8F8F8"/>
                <path d="M4.12805 0.168945H3.99488V8.89111C3.99634 9.27672 3.88904 9.65493 3.68529 9.98233C3.48155 10.3097 3.18963 10.573 2.84302 10.7421C2.46664 10.8853 2.05298 10.8975 1.66882 10.7767C1.28465 10.6559 0.952415 10.4091 0.725737 10.0763L0 11.9871C0.630417 12.617 1.4794 12.9794 2.3703 12.9992C2.7222 13.0075 3.07309 12.958 3.40897 12.8527C4.15125 12.6281 4.80578 12.1791 5.28265 11.5675C5.75952 10.956 6.03534 10.2117 6.07222 9.43707V0.168945H4.12805Z" fill="#F8F8F8"/>
                <path d="M76.5219 0.149414H74.3447V12.7866H80.2305V10.7691H76.5219V0.149414Z" fill="#F8F8F8"/>
                <path d="M84.146 0.149414H81.9688V12.7866H87.8545V10.7691H84.146V0.149414Z" fill="#F8F8F8"/>
              </svg>
            </AniLink>
          </li>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Level -1</AniLink>
        </div>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Sports</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Golf</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Tennis</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Swim</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Cricket</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Skate</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Boxing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Home Gym</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Outdoor</AniLink>
          </li>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Customer Services</AniLink>
          </li>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Click &amp; Collect</AniLink>
          </li>
        </ul>
        <ul className={styles.logolist}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">
              <svg width="201" height="10" viewBox="0 0 201 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M128.909 9.89199C127.549 9.89199 126.425 9.47351 125.602 8.71359C125.175 8.30866 124.837 7.81939 124.609 7.27683C124.381 6.73426 124.268 6.15025 124.278 5.56185C124.278 3.00894 126.15 1.21777 129.109 1.21777C130.141 1.21707 131.159 1.4524 132.086 1.90585V3.86868C131.242 3.30228 130.254 2.99099 129.238 2.97216C127.515 2.97216 126.467 3.95446 126.467 5.56185C126.467 7.10796 127.465 8.13931 129.076 8.13931C130.1 8.13931 131.137 7.78912 132.159 7.08873V9.05156C131.166 9.61552 130.042 9.9078 128.901 9.899" fill="#F8F8F8"/>
                <path d="M144.927 1.36328L143.004 4.07553L141.13 1.36328H138.572L141.931 5.95432V9.74517H144.065V5.94031L147.462 1.36328H144.927Z" fill="#F8F8F8"/>
                <path d="M157.273 9.89199C155.912 9.89199 154.788 9.47352 153.963 8.7136C153.536 8.30857 153.199 7.81931 152.971 7.27674C152.743 6.73417 152.631 6.15013 152.641 5.56185C152.641 3.00894 154.513 1.21778 157.472 1.21778C158.504 1.21683 159.522 1.45217 160.449 1.90585V3.86868C159.606 3.30233 158.618 2.99103 157.603 2.97216C155.881 2.97216 154.832 3.95446 154.832 5.56185C154.832 7.10796 155.83 8.13932 157.441 8.13932C158.465 8.13932 159.5 7.78912 160.524 7.08874V9.05156C159.531 9.61552 158.407 9.9078 157.266 9.899" fill="#F8F8F8"/>
                <path d="M168.032 1.36328V9.74517H173.838V8.12552H170.167V1.36328H168.032Z" fill="#F8F8F8"/>
                <path d="M181.039 1.36328V9.74517H186.845V8.17455H183.173V6.17494H186.583V4.61658H183.173V2.90939H186.745V1.36328H181.039Z" fill="#F8F8F8"/>
                <path d="M194.308 7.21719C195.383 7.8668 196.257 8.26777 197.218 8.26777C198.055 8.26777 198.491 8.01042 198.491 7.5184C198.491 7.02638 198.104 6.85653 197.356 6.59914C195.208 5.86198 194.159 5.16328 194.159 3.63993C194.159 2.23916 195.42 1.22363 197.381 1.22363C198.386 1.23291 199.375 1.46745 200.277 1.91V3.62771C199.191 3.08841 198.279 2.79429 197.493 2.79429C196.668 2.79429 196.245 3.03936 196.245 3.46834C196.245 3.81854 196.619 4.11972 197.393 4.40162C198.167 4.68353 198.878 4.91643 199.515 5.29814C200.265 5.75164 200.64 6.46253 200.64 7.3993C200.64 8.92089 199.414 9.89093 197.269 9.89093C196.227 9.89578 195.203 9.61545 194.308 9.08025V7.21719Z" fill="#F8F8F8"/>
                <path d="M81.8717 7.31616L74.4703 1.20703H66.6435L67.3929 2.37493L64.8838 9.54514H69.7812L71.9139 3.48679L78.734 9.54514H85.9532L88.9036 1.20703H84.0044L81.8717 7.31616Z" fill="#F8F8F8"/>
                <path d="M1.07509 0L2.57392 2.33229L0 9.54628H17.7531L18.569 7.27352H5.67839L6.18092 5.81321H16.9564L17.6007 3.92217H6.85679L7.43636 2.33054H20.3182L18.8177 0H1.07509Z" fill="#F8F8F8"/>
                <path d="M39.5594 1.20703L31.0479 7.16032L27.2203 1.20703H22.2178L27.6178 9.54514H33.8144L45.2255 1.20703H39.5594Z" fill="#F8F8F8"/>
                <path d="M50.2343 6.12727L53.8605 3.59187L55.4907 6.12727H50.2343ZM48.823 1.20703L49.5759 2.37671L39.7705 9.54516H45.3543L47.3557 8.14439H56.7934L57.6916 9.54516H62.6942L57.2941 1.20703H48.823Z" fill="#F8F8F8"/>
                <path d="M110.782 3.65419C111.404 1.37794 108.956 0.836914 102.179 0.836914C96.3872 0.836914 92.3302 1.14335 91.3234 3.65599C90.4059 5.95151 93.2898 6.13881 100.168 6.13881C103.897 6.13881 104.895 6.29292 104.696 6.86373C104.496 7.43455 103.521 7.85652 99.4287 7.85652C97.7075 7.85652 94.3596 7.93182 94.5627 7.11937L94.6765 6.74814H89.9997C88.6374 9.52518 94.5715 9.82114 98.6915 9.82114C106.485 9.82114 108.591 9.26784 109.523 6.6694C110.008 5.3159 109.376 4.21804 101.015 4.21804C98.3763 4.21804 96.4065 4.23201 96.4065 3.65069C96.4065 2.7752 98.9961 2.7752 101.626 2.7752C102.852 2.7752 106.086 2.80851 106.086 3.4266V3.65069L110.782 3.65419Z" fill="#F8F8F8"/>
              </svg>
            </AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/bns/level-minus-1">Workshop</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.9269 14.8406L10.4917 10.119C10.4489 10.1016 10.402 10.0971 10.3568 10.1061C10.3115 10.115 10.2698 10.137 10.2369 10.1694C10.2039 10.2017 10.1812 10.2429 10.1714 10.288C10.1616 10.3332 10.1652 10.3801 10.1818 10.4232L14.9091 22.8584C14.9275 22.9006 14.9578 22.9366 14.9964 22.9618C15.0349 22.9871 15.08 23.0005 15.126 23.0005C15.1721 23.0005 15.2171 22.9871 15.2557 22.9618C15.2942 22.9366 15.3246 22.9006 15.343 22.8584L16.9037 19.7764C16.9217 19.7434 16.947 19.715 16.9779 19.6936C17.0087 19.6721 17.044 19.658 17.0812 19.6526C17.1184 19.6471 17.1563 19.6504 17.192 19.6621C17.2277 19.6738 17.2601 19.6936 17.2868 19.72L21.0506 23.4838L23.5749 20.9596L19.8054 17.1958C19.7792 17.1687 19.7597 17.1359 19.7484 17.0999C19.7372 17.064 19.7344 17.0259 19.7404 16.9887C19.7465 16.9515 19.7611 16.9162 19.7831 16.8857C19.8051 16.8551 19.834 16.8301 19.8674 16.8127L22.9494 15.2519C22.9847 15.2293 23.0134 15.1978 23.0325 15.1605C23.0516 15.1232 23.0604 15.0815 23.0581 15.0397C23.0558 14.9979 23.0425 14.9574 23.0194 14.9224C22.9964 14.8875 22.9644 14.8592 22.9269 14.8406Z" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M6.81174 6.71041L2.87891 2.7832" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M5.56119 10.001H0" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M10.0967 5.56119V0" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.keyblock}>
        <div className={styles.heading}>
          <p>Key</p>
        </div>
        <ul className={styles.list}>
          <li>
            <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7959 3.94777C13.7959 3.36476 13.9665 2.79484 14.286 2.31008C14.6056 1.82532 15.0598 1.4475 15.5912 1.22439C16.1227 1.00128 16.7074 0.942901 17.2716 1.05664C17.8357 1.17038 18.3539 1.45113 18.7607 1.86338C19.1674 2.27564 19.4444 2.80088 19.5566 3.37269C19.6688 3.9445 19.6112 4.5372 19.3911 5.07583C19.171 5.61447 18.7982 6.07485 18.3199 6.39875C17.8417 6.72266 17.2794 6.89554 16.7042 6.89554V10.637" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M16.7696 23.7791H2.38916C0.975646 23.7791 0.467476 21.357 1.68824 20.4297L7.02688 16.3675L14.8012 10.4576C15.3862 10.0036 16.0703 9.76172 16.7696 9.76172C17.4689 9.76172 18.153 10.0036 18.738 10.4576L26.5123 16.3675L31.851 20.4297C33.0717 21.357 32.5636 23.7791 31.1501 23.7791H16.7696Z" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <p>Fitting Room</p>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#1C1C1C"/>
              <path d="M8.91407 1.03906H9.20825C15.0808 1.23888 18.6443 7.6998 15.7913 12.823L9.97424 23.4414C9.84747 23.6019 9.68597 23.7317 9.50187 23.8209C9.31778 23.9101 9.11586 23.9564 8.91129 23.9564C8.70672 23.9564 8.50481 23.9101 8.32072 23.8209C8.13662 23.7317 7.97512 23.6019 7.84835 23.4414L2.0313 12.8286C-0.788415 7.6776 2.74178 1.24442 8.61434 1.0446L8.91407 1.03906Z" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <p>Cash Desk</p>
          </li>
          <li>
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.9269 14.8406L10.4917 10.119C10.4489 10.1016 10.402 10.0971 10.3568 10.1061C10.3115 10.115 10.2698 10.137 10.2369 10.1694C10.2039 10.2017 10.1812 10.2429 10.1714 10.288C10.1616 10.3332 10.1652 10.3801 10.1818 10.4232L14.9091 22.8584C14.9275 22.9006 14.9578 22.9366 14.9964 22.9618C15.0349 22.9871 15.08 23.0005 15.126 23.0005C15.1721 23.0005 15.2171 22.9871 15.2557 22.9618C15.2942 22.9366 15.3246 22.9006 15.343 22.8584L16.9037 19.7764C16.9217 19.7434 16.947 19.715 16.9779 19.6936C17.0087 19.6721 17.044 19.658 17.0812 19.6526C17.1184 19.6471 17.1563 19.6504 17.192 19.6621C17.2277 19.6738 17.2601 19.6936 17.2868 19.72L21.0506 23.4838L23.5749 20.9596L19.8054 17.1958C19.7792 17.1687 19.7597 17.1359 19.7484 17.0999C19.7372 17.064 19.7344 17.0259 19.7404 16.9887C19.7465 16.9515 19.7611 16.9162 19.7831 16.8857C19.8051 16.8551 19.834 16.8301 19.8674 16.8127L22.9494 15.2519C22.9847 15.2293 23.0134 15.1978 23.0325 15.1605C23.0516 15.1232 23.0604 15.0815 23.0581 15.0397C23.0558 14.9979 23.0425 14.9574 23.0194 14.9224C22.9964 14.8875 22.9644 14.8592 22.9269 14.8406Z" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M6.81174 6.71041L2.87891 2.7832" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M5.56119 10.001H0" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M10.0967 5.56119V0" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <p>Click and Collect</p>
          </li>
        </ul>
      </div>

    </nav>

  )
}

export default Navigation
